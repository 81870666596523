import React from 'react';
import * as Icon from 'react-feather';

// Cloud Hosting Services Images
import BookSelf from "../../assets/images/services-right-image/book-self.png";
import Box from "../../assets/images/services-right-image/box.png";
import Chair from "../../assets/images/services-right-image/chair.png";
import Cloud from "../../assets/images/services-right-image/cloud.png";
import Cup from "../../assets/images/services-right-image/cup.png";
import FlowerTop from "../../assets/images/services-right-image/flower-top.png";
import HeadPhone from "../../assets/images/services-right-image/head-phone.png";
import Monitor from "../../assets/images/services-right-image/monitor.png";
import Mug from "../../assets/images/services-right-image/mug.png";
import Table from "../../assets/images/services-right-image/table.png";
import Tissue from "../../assets/images/services-right-image/tissue.png";
import WaterBottle from "../../assets/images/services-right-image/water-bottle.png";
import Wifi from "../../assets/images/services-right-image/wifi.png";
import CercleShape from "../../assets/images/services-right-image/cercle-shape.png";
import ServiceRightMainPic from "../../assets/images/services-right-image/service-right-main-pic.png"; 

import Track1 from "../../assets/images/cloudCampaign/Track1.png"
import Track2 from "../../assets/images/cloudCampaign/Track2.png"
import Track3 from "../../assets/images/cloudCampaign/Track3.png"
import Track4 from "../../assets/images/cloudCampaign/Track4.png"

// Design & Development Images
import BigMonitor from "../../assets/images/services-left-image/big-monitor.png";
import Creative from "../../assets/images/services-left-image/creative.png";
import Developer from "../../assets/images/services-left-image/developer.png";
import SmallMonitor from "../../assets/images/services-left-image/small-monitor.png";
import SmallTop from "../../assets/images/services-left-image/small-top.png";
import Target from "../../assets/images/services-left-image/target.png";
import ServiceLeftMainPic from "../../assets/images/services-left-image/service-left-main-pic.png";

const ServicesArea = () => {
    return (
        <>
            <div className="services-area ptb-80 bg-f7fafd">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Cloud Computing Fundementals</h2>
                                <div className="bar"></div>
                                <p>This first course provides an overview of cloud computing, ways to use Google Cloud, and different compute options</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Database /> A Tour of Google Cloud Hands-on Labs
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Globe /> Getting Started with Cloud Shell and glcoud
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.File /> Creating a Virtual Machine
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.TrendingUp /> App Engine: Qwik Start - Python
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Folder /> Cloud Functions: Qwik Start - Command Line
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Monitor /> Kubernetes Engine: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Mail /> Set Up Network and HTTP Load balancers
                                    </div>
                                </div>

                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 services-right-image">
                            
                           
                            <img 
                                src={Track1}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                data-wow-delay="0.6s" 
                                alt="box"
                            />
                         
                         
                            <img 
                                src={Track1}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="services-area ptb-80">
                <div className="container">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-left-image">
                            <img 
                                src={Track2}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="big-monitor"
                            />
            
                            
           
                            <img 
                                src={Track2}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>

                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Infrastructure in Google Cloud</h2>
                                <div className="bar"></div>
                                <p>This second course reviews implementing storage models, different application managed service options, and security administration in Google Cloud.</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Layout /> Cloud Storage: Qwik Start - CLI/SDK
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Code /> React Web Development
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smartphone /> Cloud SQL for MySQL: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Code /> Cloud Endpoints: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smartphone /> Google Cloud Pub/Sub: Qwik Start - Python
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Percent /> User Authentication: Identity-Aware Proxy
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.ShoppingCart /> Cloud IAM: Qwik Start
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="services-area ptb-80 bg-f7fafd">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Networking & Security in Google Cloud</h2>
                                <div className="bar"></div>
                                <p>This third course covers how to build secure networks, and cloud automation and management tools.</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Database /> Multiple VPC Networks
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Globe /> VPC Networks - Controlling Access
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.File /> HTTP Load balancer with Cloud Armor
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.TrendingUp /> Create an Internal Load Balancer
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Folder /> Cloud Monitoring: Qwik Start
                                    </div>
                                </div>

                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 services-right-image">
                            
                           
                            <img 
                                src={Track3}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                data-wow-delay="0.6s" 
                                alt="box"
                            />
                         
                            
                         
                            <img 
                                src={Track3}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="services-area ptb-80">
                <div className="container">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-left-image">
                            <img 
                                src={Track4}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="big-monitor"
                            />
            
           
                            <img 
                                src={Track4}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>

                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Data, ML, and AI in Google Cloud</h2>
                                <div className="bar"></div>
                                <p>This final course in the series reviews managed big data services, machine learning and its value, and how to demostrate your skillset in Google Cloud further by earning Skill Badges.</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Layout /> Dataproc: Qwik Start - Console
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Code /> Dataproc: Qwik Start - Command Line
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smartphone /> Dataflow: Qwik Start - Templates
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Code /> Dataflow: Qwik Start - Python
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smartphone /> Dataprep: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Percent /> AI Platform: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.ShoppingCart /> Cloud Natural Language API: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.CheckCircle /> Google Cloud Speech API: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.CheckCircle /> Video Intelligence: Qwik Start
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.CheckCircle /> Reinforcement Learning: Qwik Start
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesArea;